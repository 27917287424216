var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" Bootstrap list group ")]),
                      _c("div", { staticClass: "card-header-actions" }, [
                        _c(
                          "a",
                          {
                            staticClass: "card-header-action",
                            attrs: {
                              href:
                                "https://coreui.io/vue/docs/components/list-group",
                              rel: "noreferrer noopener",
                              target: "_blank"
                            }
                          },
                          [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("docs")
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c("CListGroupItem", [_vm._v("Cras justo odio")]),
                          _c("CListGroupItem", [
                            _vm._v("Dapibus ac facilisis in")
                          ]),
                          _c("CListGroupItem", [_vm._v("Morbi leo risus")]),
                          _c("CListGroupItem", [
                            _vm._v("Porta ac consectetur ac")
                          ]),
                          _c("CListGroupItem", [_vm._v("Vestibulum at eros")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("active items")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c("CListGroupItem", [_vm._v("Cras justo odio")]),
                          _c("CListGroupItem", { attrs: { active: "" } }, [
                            _vm._v("Dapibus ac facilisis in")
                          ]),
                          _c("CListGroupItem", [_vm._v("Morbi leo risus")]),
                          _c("CListGroupItem", [
                            _vm._v("Porta ac consectetur ac")
                          ]),
                          _c("CListGroupItem", [_vm._v("Vestibulum at eros")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("disabled items")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c("CListGroupItem", { attrs: { disabled: "" } }, [
                            _vm._v("Cras justo odio")
                          ]),
                          _c("CListGroupItem", [
                            _vm._v("Dapibus ac facilisis in")
                          ]),
                          _c("CListGroupItem", [_vm._v("Morbi leo risus")]),
                          _c("CListGroupItem", { attrs: { disabled: "" } }, [
                            _vm._v("Porta ac consectetur ac")
                          ]),
                          _c("CListGroupItem", [_vm._v("Vestibulum at eros")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("actionable items")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#some-link" } },
                            [_vm._v("Awesome link")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", active: "" } },
                            [_vm._v("Link with active state")]
                          ),
                          _c("CListGroupItem", { attrs: { href: "#" } }, [
                            _vm._v("Action links are easy")
                          ]),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#foobar", disabled: "" } },
                            [_vm._v("Disabled link")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("buttons")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c("CListGroupItem", { attrs: { tag: "button" } }, [
                            _vm._v("Button item")
                          ]),
                          _c("CListGroupItem", { attrs: { tag: "button" } }, [
                            _vm._v("I am a button")
                          ]),
                          _c(
                            "CListGroupItem",
                            { attrs: { tag: "button", disabled: "" } },
                            [_vm._v("Disabled button")]
                          ),
                          _c("CListGroupItem", { attrs: { tag: "button" } }, [
                            _vm._v("This is a button too")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("with badges")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c(
                            "CListGroupItem",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _vm._v(" Cras justo odio "),
                              _c(
                                "CBadge",
                                { attrs: { color: "primary", shape: "pill" } },
                                [_vm._v("14")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "CListGroupItem",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _vm._v(" Dapibus ac facilisis in "),
                              _c(
                                "CBadge",
                                { attrs: { color: "primary", shape: "pill" } },
                                [_vm._v("2")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "CListGroupItem",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _vm._v(" Morbi leo risus "),
                              _c(
                                "CBadge",
                                { attrs: { color: "primary", shape: "pill" } },
                                [_vm._v("1")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("colors")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c("CListGroupItem", [
                            _vm._v("This is a default list group item")
                          ]),
                          _c(
                            "CListGroupItem",
                            { attrs: { color: "primary" } },
                            [_vm._v("This is a primary list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { color: "secondary" } },
                            [_vm._v("This is a secondary list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { color: "success" } },
                            [_vm._v("This is a success list group item")]
                          ),
                          _c("CListGroupItem", { attrs: { color: "danger" } }, [
                            _vm._v("This is a danger list group item")
                          ]),
                          _c(
                            "CListGroupItem",
                            { attrs: { color: "warning" } },
                            [_vm._v("This is a warning list group item")]
                          ),
                          _c("CListGroupItem", { attrs: { color: "info" } }, [
                            _vm._v("This is a info list group item")
                          ]),
                          _c("CListGroupItem", { attrs: { color: "light" } }, [
                            _vm._v("This is a light list group item")
                          ]),
                          _c("CListGroupItem", { attrs: { color: "dark" } }, [
                            _vm._v("This is a dark list group item")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("colors active")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c("CListGroupItem", { attrs: { href: "#" } }, [
                            _vm._v("This is a default list group item")
                          ]),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "primary" } },
                            [_vm._v("This is a primary list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "secondary" } },
                            [_vm._v("This is a secondary list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "success" } },
                            [_vm._v("This is a success list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "danger" } },
                            [_vm._v("This is a danger list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "warning" } },
                            [_vm._v("This is a warning list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "info" } },
                            [_vm._v("This is a info list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "light" } },
                            [_vm._v("This is a light list group item")]
                          ),
                          _c(
                            "CListGroupItem",
                            { attrs: { href: "#", color: "dark" } },
                            [_vm._v("This is a dark list group item")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { col: "12" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("inside cards")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CCardGroup",
                        { attrs: { deck: "" } },
                        [
                          _c(
                            "CCard",
                            [
                              _c("CCardHeader", [
                                _c("b", [_vm._v("Card with list group")])
                              ]),
                              _c(
                                "CCardBody",
                                [
                                  _c(
                                    "CListGroup",
                                    [
                                      _c(
                                        "CListGroupItem",
                                        { attrs: { href: "#" } },
                                        [_vm._v("Cras justo odio")]
                                      ),
                                      _c(
                                        "CListGroupItem",
                                        { attrs: { href: "#" } },
                                        [_vm._v("Dapibus ac facilisis in")]
                                      ),
                                      _c(
                                        "CListGroupItem",
                                        { attrs: { href: "#" } },
                                        [_vm._v("Vestibulum at eros")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("CCardText", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      " Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla tempor. Laborum consequat non elit enim exercitation cillum aliqua consequat id aliqua. Esse ex consectetur mollit voluptate est in duis laboris ad sit ipsum anim Lorem. "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "CCard",
                            [
                              _c("CCardHeader", [
                                _c("b", [_vm._v("Card with flush list group")])
                              ]),
                              _c(
                                "CListGroup",
                                { attrs: { flush: "" } },
                                [
                                  _c(
                                    "CListGroupItem",
                                    { attrs: { href: "#" } },
                                    [_vm._v("Cras justo odio")]
                                  ),
                                  _c(
                                    "CListGroupItem",
                                    { attrs: { href: "#" } },
                                    [_vm._v("Dapibus ac facilisis in")]
                                  ),
                                  _c(
                                    "CListGroupItem",
                                    { attrs: { href: "#" } },
                                    [_vm._v("Vestibulum at eros")]
                                  )
                                ],
                                1
                              ),
                              _c("CCardBody", [
                                _vm._v(
                                  " Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla tempor. Laborum consequat non elit enim exercitation cillum aliqua consequat id aliqua. Esse ex consectetur mollit voluptate est in duis laboris ad sit ipsum anim Lorem. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" List group ")]),
                      _c("small", [_vm._v("custom content")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CListGroup",
                        [
                          _c(
                            "CListGroupItem",
                            {
                              staticClass: "flex-column align-items-start",
                              attrs: { href: "#", active: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex w-100 justify-content-between"
                                },
                                [
                                  _c("h5", { staticClass: "mb-1" }, [
                                    _vm._v("List group item heading")
                                  ]),
                                  _c("small", [_vm._v("3 days ago")])
                                ]
                              ),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit. "
                                )
                              ]),
                              _c("small", [
                                _vm._v("Donec id elit non mi porta.")
                              ])
                            ]
                          ),
                          _c(
                            "CListGroupItem",
                            {
                              staticClass: "flex-column align-items-start",
                              attrs: { href: "#" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex w-100 justify-content-between"
                                },
                                [
                                  _c("h5", { staticClass: "mb-1" }, [
                                    _vm._v("List group item heading")
                                  ]),
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v("3 days ago")
                                  ])
                                ]
                              ),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit. "
                                )
                              ]),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Donec id elit non mi porta.")
                              ])
                            ]
                          ),
                          _c(
                            "CListGroupItem",
                            {
                              staticClass: "flex-column align-items-start",
                              attrs: { href: "#", disabled: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex w-100 justify-content-between"
                                },
                                [
                                  _c("h5", { staticClass: "mb-1" }, [
                                    _vm._v("Disabled List group item")
                                  ]),
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v("3 days ago")
                                  ])
                                ]
                              ),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit. "
                                )
                              ]),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("Donec id elit non mi porta.")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }